$(function () {
  $('#tab-user-investment-button').on('click', function () {
    $('#tab-user-investment-contents').toggleClass('active');
  });

  $('#generate-share-link-button').on('click', function() {
    $(this).addClass('activating');
    $(this).prop('disabled', true);
    $('#button-text').fadeOut(200);

    setTimeout(function () {
      $('#user-share-activate-loading').fadeIn(200);
      $('#button-activating-text').fadeIn(200);
    }, 300);

    setTimeout(function () {
      $('#tab-user-investment-contents').removeClass('active');
    }, 2000);

    setTimeout(function () {
      $('#lean-overlay').fadeIn(400);
      $('#user-share-activated').fadeIn(400);
      $('#modal-close-global-icon').fadeIn(400);

      var generateShareLinkButton = $('#generate-share-link-button');
      generateShareLinkButton.removeClass('activating');
      $('#button-text').fadeIn();
      $('#user-share-activate-loading').fadeOut();
      $('#button-activating-text').fadeOut();
      generateShareLinkButton.prop('disabled', false);
    }, 2200);
  });

  //シェア支援要素外をクリックしたら閉じる
  $(document).on('click', function(event) {
    if(!$(event.target).closest('#tab-user-investment').length) {
      $('#tab-user-investment-contents').removeClass('active');
    }
  });

  $('#modal-close-global-icon, #lean-overlay').on('click', function () {
    $('#lean-overlay').fadeOut();
    $('#user-share-activated').fadeOut();
  });

  $('#tab-facebook-share-url-button, ' +
    '#tab-twitter-share-url-button, ' +
    '#tab-user-share-activated-url-copy-button, ' +
    '#tab-user-share-url').on('click', function(){
    if ($(this).hasClass('disabled')) {
      return false;
    }

    $.ajax({
      type: 'GET',
      url: '/api/share/' + $(this).data('token') + '/action',
      cache: false,
      dataType : 'json'
    });

    if ($(this).attr('id') === 'tab-user-share-activated-url-copy-button'
      || $(this).attr('id') === 'tab-user-share-url') {
      $('#tab-user-share-activated-url-copy-button').addClass('disabled');
      $('#tab-user-share-url').addClass('disabled');
      $('#user_share_copy_feedback').addClass('user-share-copy-feedback-activating');
      setTimeout(function(){
        $('#user_share_copy_feedback').removeClass('user-share-copy-feedback-activating');
      }, 2850);

      $('#tab-user-share-url').select();
      document.execCommand('copy');
    } else {
      $(this).addClass('disabled');
    }

  });

  var listener = function(e){
    var userAgent = window.navigator.userAgent && window.navigator.userAgent.toLowerCase();
    if (userAgent && (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/))) {
      //IE対応
      window.clipboardData.setData('Text', $('#tab-user-share-url').val());
    } else {
      e.clipboardData.setData('text/plain', $('#tab-user-share-url').val());
    }
  };
  document.addEventListener('copy', listener);
});
